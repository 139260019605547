import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"

import Layout from "../components/layout"
import Head from "../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
  logos: {
    width: "80px",
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  contactDetail: {
    margin: theme.spacing(7, 0),
  },
  profileWrap: {
    display: "grid",
    gridTemplateColumns: "120px 1fr",
    gridGap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      justifyItems: "center",
    },
  },

  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },

  profileContainer: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
}))

const People = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="People" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Our People
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Meet our team
            </Typography>
          </Container>
          <Container className={classes.profileContainer}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <div className={classes.profileWrap}>
                  <div>
                    <Avatar
                      alt="Janice Vu"
                      src="/static/images/avatar/1.jpg"
                      className={classes.avatar}
                    />
                  </div>
                  <div>
                    <Typography component="h3" variant="h5" color="textPrimary">
                      Janice Vu
                    </Typography>
                    <Typography component="h6" variant="subtitle1" gutterBottom>
                      Principal Solicitor
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      Janice Vu is an Accredited Specialist (Immigration Law).
                      She has been a practising lawyer since 1993. Prior to her
                      work as a lawyer, Janice worked with the Department of
                      Home Affairs for 14 years initially as a social worker and
                      then in the residence and compliance sections.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      Janice’s qualifications include a Bachelor of Arts and
                      Social Work from the University of Queensland, a Bachelor
                      of Law from the University of Technology and a Master of
                      Law from the University of Sydney. Janice Vu started her
                      own private legal practice in Bankstown in 1997. She took
                      leave to complete her Master of Law in 2011-2012. On
                      return to work in 2013 she opened her legal practice
                      Cabramatta.
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.profileWrap}>
                  <div>
                    <Avatar
                      alt="Janice Le"
                      src="/static/images/avatar/1.jpg"
                      className={classes.avatar}
                    />
                  </div>
                  <div>
                    <Typography component="h3" variant="h5" color="textPrimary">
                      Janice Le
                    </Typography>
                    <Typography component="h6" variant="subtitle1" gutterBottom>
                      Solicitor
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                      Janice Le has a Bachelor of Law from the University of
                      Western Sydney. She has in excess of 9 years work
                      experience as a lawyer in diverse fields. Her work
                      includes a cross-section of conveyancing, commercial
                      practice, family law, wills and estates and also
                      immigration law.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default People
